import React, { useContext } from "react";
import Page from "./Page";
import Header from "../components/Header";
import Button from "../components/Button";
import {
  useAddClientContextMutation,
  useGetMeFullQuery,
  useSaveClientConfigMutation,
} from "../gql/schema";
import { toast } from "react-toastify";
import { VscLoading } from "react-icons/vsc";
import { FiInfo } from "react-icons/fi";
import Tooltip, { TooltipChild } from "../components/Tooltip";
import TextArea from "../components/TextArea";
import Card from "../components/Card";
import { addViewsFromFile } from "../api/addViewFromFile";
import authContext from "../contexts/authContext";
import QuestionList from "../components/QuestionList";
import FileUpload from "../components/FileUpload";

export type Question = {
  question: string;
  key: string | null;
  required: "required" | "optional" | "unprompted" | null;
};

const sampleQuestions: Question[] = [
  { question: "What's your email?", key: "email", required: "required" },
  {
    question: "What's your company called?",
    key: "company",
    required: "required",
  },
  {
    question: "What made you decide to look for a new solution?",
    key: "new_solution",
    required: "required",
  },
  {
    question: "What's your budget for something like this?",
    key: "budget",
    required: "required",
  },
  {
    question: "When do you need this?",
    key: "need_time",
    required: "required",
  },
  {
    question: "Are you considering any other products?",
    key: "other_products",
    required: "optional",
  },
  {
    question: "What features are most important to you?",
    key: "important_features",
    required: "optional",
  },
];

const Dojo = () => {
  const [questions, setQuestions] = React.useState<Question[]>([]);
  const [newQuestion, setNewQuestion] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [addClientContext] = useAddClientContextMutation();
  const [saveClientConfig] = useSaveClientConfigMutation();
  const [files, setFiles] = React.useState<File[]>([]);
  const { authToken } = useContext(authContext);

  const { data, loading: dataLoading } = useGetMeFullQuery();

  const initialUnderstanding =
    data?.getMe?.seller?.client?.additionalContext?.trim() || "";
  const clientId = data?.getMe?.seller?.client?.id;
  const qualificationQuestions =
    data?.getMe?.seller?.client?.config?.qualificationQuestions;
  const initialQuestions =
    qualificationQuestions === null
      ? sampleQuestions
      : qualificationQuestions?.split("\n") || [];
  const [understandingPrompt, setUnderstandingPrompt] =
    React.useState(initialUnderstanding);

  React.useEffect(() => {
    if (!dataLoading) {
      setUnderstandingPrompt(
        data?.getMe?.seller?.client?.additionalContext || ""
      );
      if (qualificationQuestions == null) {
        setQuestions(sampleQuestions);
      } else {
        const questions: Question[] = JSON.parse(qualificationQuestions);
        setQuestions(questions);
      }
    }
  }, [data, dataLoading]);

  const disabled = React.useMemo(
    () =>
      loading ||
      (understandingPrompt.trim() === initialUnderstanding &&
        files.length === 0 &&
        JSON.stringify(questions) === JSON.stringify(initialQuestions)),
    [
      understandingPrompt,
      initialUnderstanding,
      loading,
      files,
      questions,
      initialQuestions,
    ]
  );

  const handleSubmit = async () => {
    if (disabled) return;
    setErrorText("");
    setLoading(true);
    let success = true;
    try {
      if (understandingPrompt.trim() !== initialUnderstanding) {
        const response = await addClientContext({
          variables: {
            text: understandingPrompt,
          },
        });
        if (response?.data?.addClientContext?.client?.id) {
        } else {
          success = false;
          setErrorText(
            response?.data?.addClientContext?.error?.message ||
              "Something went wrong"
          );
        }
      }
      if (JSON.stringify(questions) !== JSON.stringify(initialQuestions)) {
        const response = await saveClientConfig({
          variables: {
            qualificationQuestions: JSON.stringify(questions),
          },
        });
        if (response?.data?.saveClientConfig?.config?.id) {
        } else {
          success = false;
          setErrorText(
            response?.data?.saveClientConfig?.error?.message ||
              "Something went wrong"
          );
          return;
        }
      }
      for (let f of files) {
        if (!!f && !!authToken) {
          if (!f.name.endsWith(".pdf")) {
            setErrorText("Only PDF files are supported.");
            setLoading(false);
            return;
          }
          const response = await addViewsFromFile(clientId, f, authToken);

          if (!!response?.views || (response?.views || []).length > 0) {
            setFiles([]);
          } else {
            success = false;
            setErrorText(
              response?.data?.addViewsFromFile?.error?.message ||
                "Something went wrong"
            );
            return;
          }
        }
      }

      if (success) {
        toast.success(`AI trained successfully!`);
      }
    } catch (e) {
      setErrorText(e?.toString() || "");
    }
    setLoading(false);
  };

  return (
    <Page>
      <Header title="AI training dojo" />
      <div className="mb-4 flex">
        <div className="flex-1 mr-8 max-w-[500px]">
          <div className="font-semibold text-xl mb-2">
            Conversation instructions
          </div>
          <div className="mb-4">
            <p className="text-gray-500 font-regular max-w-2xl">
              Tell your AI things to make it smarter.{" "}
            </p>
          </div>
          <QuestionList
            questions={questions}
            setQuestions={setQuestions}
            newQuestion={newQuestion}
            setNewQuestion={setNewQuestion}
            loading={dataLoading}
          />

          <Card>
            <div className="">
              <label
                htmlFor="description"
                className="mb-2 text-sm font-semibold text-gray-900 dark:text-white flex items-center"
              >
                Set instructions
                <Tooltip
                  itemChild={<FiInfo className="ml-1 text-gray-500" />}
                  popoverChild={
                    <TooltipChild
                      text={
                        "Tell the AI what its objective of the call should be, how it should speak, and any other information it should know."
                      }
                    />
                  }
                ></Tooltip>
              </label>
              <TextArea
                id="description"
                required
                value={understandingPrompt}
                onChange={(e) => setUnderstandingPrompt(e.target.value)}
                rows={4}
                placeholder="Eg. Find out their name, company, and use case before booking a call with them."
                className="border-none !bg-gray-100 w-full"
              />
            </div>
          </Card>
        </div>

        <div className="flex-1 max-w-[500px]">
          <div className="font-semibold text-xl mb-2">Sales materials</div>
          <div className="mb-4">
            <p className="text-gray-500 font-regular max-w-2xl">
              Add resources your AI can use in answers.{" "}
            </p>
          </div>
          <FileUpload files={files} setFiles={setFiles} />
        </div>
      </div>

      {errorText && (
        <div className="text-failure-red text-sm mb-4">{errorText}</div>
      )}

      <div className="flex items-center">
        <Button onClick={handleSubmit} disabled={disabled} className="mr-2">
          Save
        </Button>
        <a
          href={
            clientId
              ? `${process.env.REACT_APP_TESTING_ROOT}/accounts/${clientId}?fresh=true`
              : undefined
          }
          className="mr-2"
          onClick={(e) => {
            if (!clientId) {
              e.preventDefault();
            }
          }}
        >
          <Button variant="outlined" disabled={!clientId}>
            Visit playground
          </Button>
        </a>

        {loading && <VscLoading className="animate-spin-fast text-gray-500" />}
        {loading && (
          <div className="text-gray-500 ml-2">
            This might take a few moments...
          </div>
        )}
      </div>
    </Page>
  );
};

export default Dojo;
