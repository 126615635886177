import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSONString: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type AddClient = {
  __typename?: 'AddClient';
  client?: Maybe<ClientType>;
  error?: Maybe<ErrorType>;
};

export type AddClientContext = {
  __typename?: 'AddClientContext';
  client?: Maybe<ClientType>;
  error?: Maybe<ErrorType>;
};

export type AddSeller = {
  __typename?: 'AddSeller';
  error?: Maybe<ErrorType>;
  seller?: Maybe<SellerType>;
};

export type AddTrainingContent = {
  __typename?: 'AddTrainingContent';
  error?: Maybe<ErrorType>;
  view?: Maybe<ViewType>;
};

export type AdminStatsType = {
  __typename?: 'AdminStatsType';
  accuracyLevel?: Maybe<Scalars['Float']['output']>;
  numQuestions?: Maybe<Scalars['Float']['output']>;
  questionsPerUser?: Maybe<Scalars['Float']['output']>;
  recentMistakes?: Maybe<Array<Maybe<MessageType>>>;
};

export type AggregatedMessageType = {
  __typename?: 'AggregatedMessageType';
  aggregatedBody?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  endDatetime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  messages: Array<MessageType>;
  startDatetime?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type BuyerType = {
  __typename?: 'BuyerType';
  client?: Maybe<ClientType>;
  conversations: Array<ConversationType>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  encryptedEmail?: Maybe<Scalars['String']['output']>;
  hashedEmail?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  recentMessages?: Maybe<Array<MessageType>>;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};


export type BuyerTypeRecentMessagesArgs = {
  timeFrame?: InputMaybe<Scalars['String']['input']>;
};

/** An enumeration. */
export enum BuyingMessageSenderChoices {
  /** Buyer */
  By = 'BY',
  /** Chatbot */
  Cb = 'CB',
  /** Chatbot Default */
  Cd = 'CD',
  /** Seller */
  Se = 'SE',
  /** Status */
  St = 'ST'
}

/** An enumeration. */
export enum BuyingMessageSentimentChoices {
  /** Negative */
  Negative = 'NEGATIVE',
  /** Neutral */
  Neutral = 'NEUTRAL',
  /** Positive */
  Positive = 'POSITIVE'
}

export type ChangeSellerClient = {
  __typename?: 'ChangeSellerClient';
  error?: Maybe<ErrorType>;
  seller?: Maybe<SellerType>;
};

export type ClientConfigType = {
  __typename?: 'ClientConfigType';
  client: ClientType;
  createdAt: Scalars['DateTime']['output'];
  ctaUrl?: Maybe<Scalars['String']['output']>;
  defaultEmailAddress?: Maybe<Scalars['String']['output']>;
  demoConfig?: Maybe<Scalars['String']['output']>;
  freshdeskApiKey?: Maybe<Scalars['String']['output']>;
  hasFreshdesk?: Maybe<Scalars['Boolean']['output']>;
  hasSlack?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  qualificationQuestions?: Maybe<Scalars['JSONString']['output']>;
  slackAccessToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientType = {
  __typename?: 'ClientType';
  additionalContext?: Maybe<Scalars['String']['output']>;
  buyers: Array<BuyerType>;
  config?: Maybe<ClientConfigType>;
  conversations: Array<ConversationType>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  embedScript?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  plan?: Maybe<Scalars['String']['output']>;
  sellers: Array<SellerType>;
  updatedAt: Scalars['DateTime']['output'];
  views: Array<ViewType>;
  website?: Maybe<Scalars['String']['output']>;
};

export type ConversationActionType = {
  __typename?: 'ConversationActionType';
  conversation: ConversationType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ConversationType = {
  __typename?: 'ConversationType';
  actions: Array<ConversationActionType>;
  buyer: BuyerType;
  client?: Maybe<ClientType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  lastResponses?: Maybe<Array<MessageType>>;
  messages: Array<MessageType>;
  qualificationQuestions?: Maybe<Scalars['JSONString']['output']>;
  slackThreadId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CreateConversationAction = {
  __typename?: 'CreateConversationAction';
  action?: Maybe<ConversationActionType>;
  error?: Maybe<ErrorType>;
};

export type DeleteView = {
  __typename?: 'DeleteView';
  error?: Maybe<ErrorType>;
};

export type ErrorType = {
  __typename?: 'ErrorType';
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GenerateQuestion = {
  __typename?: 'GenerateQuestion';
  error?: Maybe<ErrorType>;
  question?: Maybe<Scalars['String']['output']>;
};

export type GenerateQuestionKey = {
  __typename?: 'GenerateQuestionKey';
  error?: Maybe<ErrorType>;
  key?: Maybe<Scalars['String']['output']>;
};

export type MessageFeedbackType = {
  __typename?: 'MessageFeedbackType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  message: MessageType;
  positive: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MessageType = {
  __typename?: 'MessageType';
  aggregatedMessages: Array<AggregatedMessageType>;
  body?: Maybe<Scalars['String']['output']>;
  conversation: ConversationType;
  createdAt: Scalars['DateTime']['output'];
  feedback: Array<MessageFeedbackType>;
  id: Scalars['UUID']['output'];
  messageViews: Array<MessageViewType>;
  previousBuyerMessage?: Maybe<MessageType>;
  recording?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<BuyingMessageSenderChoices>;
  sentiment?: Maybe<BuyingMessageSentimentChoices>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MessageViewType = {
  __typename?: 'MessageViewType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  message: MessageType;
  updatedAt: Scalars['DateTime']['output'];
  view: ViewType;
};

export type Mutation = {
  __typename?: 'Mutation';
  addClient?: Maybe<AddClient>;
  addClientContext?: Maybe<AddClientContext>;
  addSeller?: Maybe<AddSeller>;
  addTrainingContent?: Maybe<AddTrainingContent>;
  changeSellerClient?: Maybe<ChangeSellerClient>;
  createConversationAction?: Maybe<CreateConversationAction>;
  deleteView?: Maybe<DeleteView>;
  generateQuestion?: Maybe<GenerateQuestion>;
  generateQuestionKey?: Maybe<GenerateQuestionKey>;
  removeConnection?: Maybe<RemoveConnection>;
  saveBuyer?: Maybe<SaveBuyer>;
  saveClientConfig?: Maybe<SaveClientConfig>;
  saveFeedback?: Maybe<SaveFeedback>;
  saveView?: Maybe<SaveView>;
  searchMessageView?: Maybe<SearchMessageView>;
  startConversation?: Maybe<StartConversation>;
  updateClient?: Maybe<UpdateClient>;
  updateUser?: Maybe<UpdateUser>;
  updateViewList?: Maybe<UpdateViewList>;
};


export type MutationAddClientArgs = {
  name: Scalars['String']['input'];
  websiteUrl: Scalars['String']['input'];
};


export type MutationAddClientContextArgs = {
  text: Scalars['String']['input'];
};


export type MutationAddSellerArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationAddTrainingContentArgs = {
  text: Scalars['String']['input'];
  viewIds: Array<InputMaybe<Scalars['UUID']['input']>>;
};


export type MutationChangeSellerClientArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationCreateConversationActionArgs = {
  conversationId: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};


export type MutationDeleteViewArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationGenerateQuestionArgs = {
  conversationId: Scalars['UUID']['input'];
};


export type MutationGenerateQuestionKeyArgs = {
  question: Scalars['String']['input'];
};


export type MutationRemoveConnectionArgs = {
  connectionName: Scalars['String']['input'];
};


export type MutationSaveBuyerArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationSaveClientConfigArgs = {
  qualificationQuestions?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveFeedbackArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  messageId: Scalars['UUID']['input'];
  positive?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveViewArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  preview: Scalars['String']['input'];
  promptText: Scalars['String']['input'];
};


export type MutationSearchMessageViewArgs = {
  messageId: Scalars['UUID']['input'];
  viewId: Scalars['UUID']['input'];
};


export type MutationUpdateClientArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  linkedinCookie?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateViewListArgs = {
  viewsList?: InputMaybe<Array<InputMaybe<ViewInput>>>;
};

export type OverviewType = {
  __typename?: 'OverviewType';
  keyMessages?: Maybe<Array<Maybe<AggregatedMessageType>>>;
  keyPages?: Maybe<Array<Maybe<ViewType>>>;
  keyUsers?: Maybe<Array<Maybe<BuyerType>>>;
  numConversations?: Maybe<Scalars['Int']['output']>;
  numMessages?: Maybe<Scalars['Int']['output']>;
  numPages?: Maybe<Scalars['Int']['output']>;
  numUsers?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  countConversations?: Maybe<Scalars['Int']['output']>;
  getAdminStats?: Maybe<AdminStatsType>;
  getConversation?: Maybe<ConversationType>;
  getFirstView?: Maybe<ViewType>;
  getMe?: Maybe<UserType>;
  getOverview?: Maybe<OverviewType>;
  getView?: Maybe<ViewType>;
  hello?: Maybe<Scalars['String']['output']>;
  listClients?: Maybe<Array<Maybe<ClientType>>>;
  listConversations?: Maybe<Array<Maybe<ConversationType>>>;
  listSellers?: Maybe<Array<Maybe<SellerType>>>;
  listViews?: Maybe<Array<Maybe<ViewType>>>;
};


export type QueryGetAdminStatsArgs = {
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  timeFrame: Scalars['String']['input'];
};


export type QueryGetConversationArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryGetOverviewArgs = {
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  timeFrame: Scalars['String']['input'];
};


export type QueryGetViewArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryListConversationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListViewsArgs = {
  url?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveConnection = {
  __typename?: 'RemoveConnection';
  config?: Maybe<ClientConfigType>;
  error?: Maybe<ErrorType>;
};

export type SaveBuyer = {
  __typename?: 'SaveBuyer';
  buyer?: Maybe<BuyerType>;
  error?: Maybe<ErrorType>;
};

export type SaveClientConfig = {
  __typename?: 'SaveClientConfig';
  config?: Maybe<ClientConfigType>;
  error?: Maybe<ErrorType>;
};

export type SaveFeedback = {
  __typename?: 'SaveFeedback';
  error?: Maybe<ErrorType>;
  feedback?: Maybe<MessageFeedbackType>;
};

export type SaveView = {
  __typename?: 'SaveView';
  error?: Maybe<ErrorType>;
  view?: Maybe<ViewType>;
};

export type SearchMessageView = {
  __typename?: 'SearchMessageView';
  error?: Maybe<ErrorType>;
  messageView?: Maybe<MessageViewType>;
};

export type SellerType = {
  __typename?: 'SellerType';
  client: ClientType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};

export type SentimentCountObject = {
  __typename?: 'SentimentCountObject';
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
};

export type StartConversation = {
  __typename?: 'StartConversation';
  conversation?: Maybe<ConversationType>;
  error?: Maybe<ErrorType>;
};

export type UpdateClient = {
  __typename?: 'UpdateClient';
  client?: Maybe<ClientType>;
  error?: Maybe<ErrorType>;
};

export type UpdateUser = {
  __typename?: 'UpdateUser';
  error?: Maybe<ErrorType>;
  user?: Maybe<UserType>;
};

export type UpdateViewList = {
  __typename?: 'UpdateViewList';
  error?: Maybe<ErrorType>;
  views?: Maybe<Array<Maybe<ViewType>>>;
};

export type UserType = {
  __typename?: 'UserType';
  buyer?: Maybe<BuyerType>;
  createdAt: Scalars['DateTime']['output'];
  dateJoined: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  password: Scalars['String']['output'];
  seller?: Maybe<SellerType>;
  updatedAt: Scalars['DateTime']['output'];
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']['output'];
};

export type ViewInput = {
  archived: Scalars['Boolean']['input'];
  id: Scalars['UUID']['input'];
};

export type ViewType = {
  __typename?: 'ViewType';
  archived: Scalars['Boolean']['output'];
  children: Array<ViewType>;
  clickEvent?: Maybe<Scalars['JSONString']['output']>;
  client: ClientType;
  createdAt: Scalars['DateTime']['output'];
  embedding?: Maybe<Scalars['String']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  linkableUrl?: Maybe<Scalars['String']['output']>;
  messageViews: Array<MessageViewType>;
  notableHtml?: Maybe<Scalars['JSONString']['output']>;
  pageSentimentCount?: Maybe<SentimentCountObject>;
  pageText?: Maybe<Scalars['String']['output']>;
  parentView?: Maybe<ViewType>;
  preview?: Maybe<Scalars['String']['output']>;
  promptText?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};


export type ViewTypePageSentimentCountArgs = {
  timeFrame: Scalars['String']['input'];
};

export type AddClientMutationVariables = Exact<{
  name: Scalars['String']['input'];
  websiteUrl: Scalars['String']['input'];
}>;


export type AddClientMutation = { __typename?: 'Mutation', addClient?: { __typename?: 'AddClient', client?: { __typename?: 'ClientType', id: any, name: string } | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type AddClientContextMutationVariables = Exact<{
  text: Scalars['String']['input'];
}>;


export type AddClientContextMutation = { __typename?: 'Mutation', addClientContext?: { __typename?: 'AddClientContext', error?: { __typename?: 'ErrorType', message?: string | null } | null, client?: { __typename?: 'ClientType', id: any, additionalContext?: string | null } | null } | null };

export type AddSellerMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type AddSellerMutation = { __typename?: 'Mutation', addSeller?: { __typename?: 'AddSeller', seller?: { __typename?: 'SellerType', id: any, user: { __typename?: 'UserType', id: string, password: string } } | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type AddTrainingContentMutationVariables = Exact<{
  text: Scalars['String']['input'];
  viewIds: Array<InputMaybe<Scalars['UUID']['input']>> | InputMaybe<Scalars['UUID']['input']>;
}>;


export type AddTrainingContentMutation = { __typename?: 'Mutation', addTrainingContent?: { __typename?: 'AddTrainingContent', error?: { __typename?: 'ErrorType', message?: string | null } | null, view?: { __typename?: 'ViewType', id: any, preview?: string | null } | null } | null };

export type ChangeSellerClientMutationVariables = Exact<{
  clientId: Scalars['UUID']['input'];
}>;


export type ChangeSellerClientMutation = { __typename?: 'Mutation', changeSellerClient?: { __typename?: 'ChangeSellerClient', seller?: { __typename?: 'SellerType', id: any } | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type DeleteViewMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type DeleteViewMutation = { __typename?: 'Mutation', deleteView?: { __typename?: 'DeleteView', error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type GenerateQuestionKeyMutationVariables = Exact<{
  question: Scalars['String']['input'];
}>;


export type GenerateQuestionKeyMutation = { __typename?: 'Mutation', generateQuestionKey?: { __typename?: 'GenerateQuestionKey', key?: string | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type GetAdminStatsQueryVariables = Exact<{
  timeFrame: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAdminStatsQuery = { __typename?: 'Query', getAdminStats?: { __typename?: 'AdminStatsType', numQuestions?: number | null, accuracyLevel?: number | null, questionsPerUser?: number | null, recentMistakes?: Array<{ __typename?: 'MessageType', id: any, conversation: { __typename?: 'ConversationType', id: any, client?: { __typename?: 'ClientType', id: any, name: string } | null }, previousBuyerMessage?: { __typename?: 'MessageType', id: any, body?: string | null, createdAt: any } | null } | null> | null } | null };

export type GetConversationQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetConversationQuery = { __typename?: 'Query', getConversation?: { __typename?: 'ConversationType', id: any, buyer: { __typename?: 'BuyerType', encryptedEmail?: string | null, user: { __typename?: 'UserType', email?: string | null }, conversations: Array<{ __typename?: 'ConversationType', id: any }> }, messages: Array<{ __typename?: 'MessageType', id: any, body?: string | null, createdAt: any, sender?: BuyingMessageSenderChoices | null, sentiment?: BuyingMessageSentimentChoices | null, feedback: Array<{ __typename?: 'MessageFeedbackType', positive: boolean, text?: string | null }>, messageViews: Array<{ __typename?: 'MessageViewType', id: any, view: { __typename?: 'ViewType', promptText?: string | null, image?: string | null, url?: string | null, clickEvent?: any | null } }> }> } | null };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', getMe?: { __typename?: 'UserType', id: string, email?: string | null, isAdmin?: boolean | null, seller?: { __typename?: 'SellerType', client: { __typename?: 'ClientType', id: any, name: string, plan?: string | null, config?: { __typename?: 'ClientConfigType', hasSlack?: boolean | null, hasFreshdesk?: boolean | null } | null } } | null } | null };

export type GetMeFullQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeFullQuery = { __typename?: 'Query', getMe?: { __typename?: 'UserType', id: string, email?: string | null, seller?: { __typename?: 'SellerType', client: { __typename?: 'ClientType', id: any, name: string, website?: string | null, additionalContext?: string | null, config?: { __typename?: 'ClientConfigType', qualificationQuestions?: any | null } | null } } | null } | null };

export type GetOverviewQueryVariables = Exact<{
  timeFrame: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetOverviewQuery = { __typename?: 'Query', getOverview?: { __typename?: 'OverviewType', numMessages?: number | null, numUsers?: number | null, numPages?: number | null, numConversations?: number | null, keyUsers?: Array<{ __typename?: 'BuyerType', id: any, email?: string | null, recentMessages?: Array<{ __typename?: 'MessageType', id: any, body?: string | null, createdAt: any, sentiment?: BuyingMessageSentimentChoices | null, conversation: { __typename?: 'ConversationType', id: any } }> | null } | null> | null, keyPages?: Array<{ __typename?: 'ViewType', id: any, url?: string | null, pageSentimentCount?: { __typename?: 'SentimentCountObject', positive?: number | null, negative?: number | null, neutral?: number | null } | null } | null> | null, keyMessages?: Array<{ __typename?: 'AggregatedMessageType', id: any, aggregatedBody?: string | null, messages: Array<{ __typename?: 'MessageType', id: any, conversation: { __typename?: 'ConversationType', id: any, buyer: { __typename?: 'BuyerType', email?: string | null } } }> } | null> | null } | null };

export type GetViewQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetViewQuery = { __typename?: 'Query', getView?: { __typename?: 'ViewType', id: any, html?: string | null, url?: string | null } | null };

export type ListClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListClientsQuery = { __typename?: 'Query', listClients?: Array<{ __typename?: 'ClientType', id: any, name: string } | null> | null };

export type ListConversationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListConversationsQuery = { __typename?: 'Query', countConversations?: number | null, listConversations?: Array<{ __typename?: 'ConversationType', id: any, createdAt: any, buyer: { __typename?: 'BuyerType', id: any, encryptedEmail?: string | null }, messages: Array<{ __typename?: 'MessageType', id: any, body?: string | null, createdAt: any, sentiment?: BuyingMessageSentimentChoices | null, sender?: BuyingMessageSenderChoices | null, feedback: Array<{ __typename?: 'MessageFeedbackType', id: any, positive: boolean }>, messageViews: Array<{ __typename?: 'MessageViewType', view: { __typename?: 'ViewType', id: any, preview?: string | null, url?: string | null, image?: string | null } }> }> } | null> | null };

export type ListSellersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSellersQuery = { __typename?: 'Query', listSellers?: Array<{ __typename?: 'SellerType', id: any, user: { __typename?: 'UserType', email?: string | null } } | null> | null };

export type ListViewsQueryVariables = Exact<{
  url?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListViewsQuery = { __typename?: 'Query', listViews?: Array<{ __typename?: 'ViewType', id: any, url?: string | null, preview?: string | null, image?: string | null } | null> | null };

export type RemoveConnectionMutationVariables = Exact<{
  connectionName: Scalars['String']['input'];
}>;


export type RemoveConnectionMutation = { __typename?: 'Mutation', removeConnection?: { __typename?: 'RemoveConnection', config?: { __typename?: 'ClientConfigType', id: any } | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type SaveClientConfigMutationVariables = Exact<{
  qualificationQuestions?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveClientConfigMutation = { __typename?: 'Mutation', saveClientConfig?: { __typename?: 'SaveClientConfig', config?: { __typename?: 'ClientConfigType', qualificationQuestions?: any | null, id: any } | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type SaveViewMutationVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']['input']>;
  preview: Scalars['String']['input'];
  promptText: Scalars['String']['input'];
}>;


export type SaveViewMutation = { __typename?: 'Mutation', saveView?: { __typename?: 'SaveView', view?: { __typename?: 'ViewType', id: any, preview?: string | null, promptText?: string | null } | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', updateClient?: { __typename?: 'UpdateClient', client?: { __typename?: 'ClientType', id: any, name: string, description?: string | null, website?: string | null } | null, error?: { __typename?: 'ErrorType', message?: string | null, field?: string | null } | null } | null };

export type UpdateViewListMutationVariables = Exact<{
  viewsList?: InputMaybe<Array<InputMaybe<ViewInput>> | InputMaybe<ViewInput>>;
}>;


export type UpdateViewListMutation = { __typename?: 'Mutation', updateViewList?: { __typename?: 'UpdateViewList', views?: Array<{ __typename?: 'ViewType', id: any } | null> | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  linkedinCookie?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'UpdateUser', user?: { __typename?: 'UserType', id: string, seller?: { __typename?: 'SellerType', client: { __typename?: 'ClientType', id: any } } | null } | null, error?: { __typename?: 'ErrorType', message?: string | null, field?: string | null } | null } | null };


export const AddClientDocument = gql`
    mutation AddClient($name: String!, $websiteUrl: String!) {
  addClient(name: $name, websiteUrl: $websiteUrl) {
    client {
      id
      name
    }
    error {
      message
    }
  }
}
    `;
export type AddClientMutationFn = Apollo.MutationFunction<AddClientMutation, AddClientMutationVariables>;

/**
 * __useAddClientMutation__
 *
 * To run a mutation, you first call `useAddClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutation, { data, loading, error }] = useAddClientMutation({
 *   variables: {
 *      name: // value for 'name'
 *      websiteUrl: // value for 'websiteUrl'
 *   },
 * });
 */
export function useAddClientMutation(baseOptions?: Apollo.MutationHookOptions<AddClientMutation, AddClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientMutation, AddClientMutationVariables>(AddClientDocument, options);
      }
export type AddClientMutationHookResult = ReturnType<typeof useAddClientMutation>;
export type AddClientMutationResult = Apollo.MutationResult<AddClientMutation>;
export type AddClientMutationOptions = Apollo.BaseMutationOptions<AddClientMutation, AddClientMutationVariables>;
export const AddClientContextDocument = gql`
    mutation AddClientContext($text: String!) {
  addClientContext(text: $text) {
    error {
      message
    }
    client {
      id
      additionalContext
    }
  }
}
    `;
export type AddClientContextMutationFn = Apollo.MutationFunction<AddClientContextMutation, AddClientContextMutationVariables>;

/**
 * __useAddClientContextMutation__
 *
 * To run a mutation, you first call `useAddClientContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientContextMutation, { data, loading, error }] = useAddClientContextMutation({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useAddClientContextMutation(baseOptions?: Apollo.MutationHookOptions<AddClientContextMutation, AddClientContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientContextMutation, AddClientContextMutationVariables>(AddClientContextDocument, options);
      }
export type AddClientContextMutationHookResult = ReturnType<typeof useAddClientContextMutation>;
export type AddClientContextMutationResult = Apollo.MutationResult<AddClientContextMutation>;
export type AddClientContextMutationOptions = Apollo.BaseMutationOptions<AddClientContextMutation, AddClientContextMutationVariables>;
export const AddSellerDocument = gql`
    mutation AddSeller($firstName: String!, $lastName: String!, $email: String!) {
  addSeller(firstName: $firstName, lastName: $lastName, email: $email) {
    seller {
      id
      user {
        id
        password
      }
    }
    error {
      message
    }
  }
}
    `;
export type AddSellerMutationFn = Apollo.MutationFunction<AddSellerMutation, AddSellerMutationVariables>;

/**
 * __useAddSellerMutation__
 *
 * To run a mutation, you first call `useAddSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSellerMutation, { data, loading, error }] = useAddSellerMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddSellerMutation(baseOptions?: Apollo.MutationHookOptions<AddSellerMutation, AddSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSellerMutation, AddSellerMutationVariables>(AddSellerDocument, options);
      }
export type AddSellerMutationHookResult = ReturnType<typeof useAddSellerMutation>;
export type AddSellerMutationResult = Apollo.MutationResult<AddSellerMutation>;
export type AddSellerMutationOptions = Apollo.BaseMutationOptions<AddSellerMutation, AddSellerMutationVariables>;
export const AddTrainingContentDocument = gql`
    mutation AddTrainingContent($text: String!, $viewIds: [UUID]!) {
  addTrainingContent(text: $text, viewIds: $viewIds) {
    error {
      message
    }
    view {
      id
      preview
    }
  }
}
    `;
export type AddTrainingContentMutationFn = Apollo.MutationFunction<AddTrainingContentMutation, AddTrainingContentMutationVariables>;

/**
 * __useAddTrainingContentMutation__
 *
 * To run a mutation, you first call `useAddTrainingContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingContentMutation, { data, loading, error }] = useAddTrainingContentMutation({
 *   variables: {
 *      text: // value for 'text'
 *      viewIds: // value for 'viewIds'
 *   },
 * });
 */
export function useAddTrainingContentMutation(baseOptions?: Apollo.MutationHookOptions<AddTrainingContentMutation, AddTrainingContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrainingContentMutation, AddTrainingContentMutationVariables>(AddTrainingContentDocument, options);
      }
export type AddTrainingContentMutationHookResult = ReturnType<typeof useAddTrainingContentMutation>;
export type AddTrainingContentMutationResult = Apollo.MutationResult<AddTrainingContentMutation>;
export type AddTrainingContentMutationOptions = Apollo.BaseMutationOptions<AddTrainingContentMutation, AddTrainingContentMutationVariables>;
export const ChangeSellerClientDocument = gql`
    mutation ChangeSellerClient($clientId: UUID!) {
  changeSellerClient(clientId: $clientId) {
    seller {
      id
    }
    error {
      message
    }
  }
}
    `;
export type ChangeSellerClientMutationFn = Apollo.MutationFunction<ChangeSellerClientMutation, ChangeSellerClientMutationVariables>;

/**
 * __useChangeSellerClientMutation__
 *
 * To run a mutation, you first call `useChangeSellerClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSellerClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSellerClientMutation, { data, loading, error }] = useChangeSellerClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useChangeSellerClientMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSellerClientMutation, ChangeSellerClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSellerClientMutation, ChangeSellerClientMutationVariables>(ChangeSellerClientDocument, options);
      }
export type ChangeSellerClientMutationHookResult = ReturnType<typeof useChangeSellerClientMutation>;
export type ChangeSellerClientMutationResult = Apollo.MutationResult<ChangeSellerClientMutation>;
export type ChangeSellerClientMutationOptions = Apollo.BaseMutationOptions<ChangeSellerClientMutation, ChangeSellerClientMutationVariables>;
export const DeleteViewDocument = gql`
    mutation DeleteView($id: UUID!) {
  deleteView(id: $id) {
    error {
      message
    }
  }
}
    `;
export type DeleteViewMutationFn = Apollo.MutationFunction<DeleteViewMutation, DeleteViewMutationVariables>;

/**
 * __useDeleteViewMutation__
 *
 * To run a mutation, you first call `useDeleteViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteViewMutation, { data, loading, error }] = useDeleteViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteViewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteViewMutation, DeleteViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteViewMutation, DeleteViewMutationVariables>(DeleteViewDocument, options);
      }
export type DeleteViewMutationHookResult = ReturnType<typeof useDeleteViewMutation>;
export type DeleteViewMutationResult = Apollo.MutationResult<DeleteViewMutation>;
export type DeleteViewMutationOptions = Apollo.BaseMutationOptions<DeleteViewMutation, DeleteViewMutationVariables>;
export const GenerateQuestionKeyDocument = gql`
    mutation GenerateQuestionKey($question: String!) {
  generateQuestionKey(question: $question) {
    key
    error {
      message
    }
  }
}
    `;
export type GenerateQuestionKeyMutationFn = Apollo.MutationFunction<GenerateQuestionKeyMutation, GenerateQuestionKeyMutationVariables>;

/**
 * __useGenerateQuestionKeyMutation__
 *
 * To run a mutation, you first call `useGenerateQuestionKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateQuestionKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateQuestionKeyMutation, { data, loading, error }] = useGenerateQuestionKeyMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useGenerateQuestionKeyMutation(baseOptions?: Apollo.MutationHookOptions<GenerateQuestionKeyMutation, GenerateQuestionKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateQuestionKeyMutation, GenerateQuestionKeyMutationVariables>(GenerateQuestionKeyDocument, options);
      }
export type GenerateQuestionKeyMutationHookResult = ReturnType<typeof useGenerateQuestionKeyMutation>;
export type GenerateQuestionKeyMutationResult = Apollo.MutationResult<GenerateQuestionKeyMutation>;
export type GenerateQuestionKeyMutationOptions = Apollo.BaseMutationOptions<GenerateQuestionKeyMutation, GenerateQuestionKeyMutationVariables>;
export const GetAdminStatsDocument = gql`
    query GetAdminStats($timeFrame: String!, $offset: Boolean) {
  getAdminStats(timeFrame: $timeFrame, offset: $offset) {
    numQuestions
    recentMistakes {
      id
      conversation {
        id
        client {
          id
          name
        }
      }
      previousBuyerMessage {
        id
        body
        createdAt
      }
    }
    accuracyLevel
    questionsPerUser
  }
}
    `;

/**
 * __useGetAdminStatsQuery__
 *
 * To run a query within a React component, call `useGetAdminStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStatsQuery({
 *   variables: {
 *      timeFrame: // value for 'timeFrame'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAdminStatsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminStatsQuery, GetAdminStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminStatsQuery, GetAdminStatsQueryVariables>(GetAdminStatsDocument, options);
      }
export function useGetAdminStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminStatsQuery, GetAdminStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminStatsQuery, GetAdminStatsQueryVariables>(GetAdminStatsDocument, options);
        }
export type GetAdminStatsQueryHookResult = ReturnType<typeof useGetAdminStatsQuery>;
export type GetAdminStatsLazyQueryHookResult = ReturnType<typeof useGetAdminStatsLazyQuery>;
export type GetAdminStatsQueryResult = Apollo.QueryResult<GetAdminStatsQuery, GetAdminStatsQueryVariables>;
export const GetConversationDocument = gql`
    query GetConversation($id: UUID!) {
  getConversation(id: $id) {
    id
    buyer {
      encryptedEmail
      user {
        email
      }
      conversations {
        id
      }
    }
    messages {
      id
      body
      createdAt
      sender
      sentiment
      feedback {
        positive
        text
      }
      messageViews {
        id
        view {
          promptText
          image
          url
          clickEvent
        }
      }
    }
  }
}
    `;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConversationQuery(baseOptions: Apollo.QueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, options);
      }
export function useGetConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, options);
        }
export type GetConversationQueryHookResult = ReturnType<typeof useGetConversationQuery>;
export type GetConversationLazyQueryHookResult = ReturnType<typeof useGetConversationLazyQuery>;
export type GetConversationQueryResult = Apollo.QueryResult<GetConversationQuery, GetConversationQueryVariables>;
export const GetMeDocument = gql`
    query GetMe {
  getMe {
    id
    email
    isAdmin
    seller {
      client {
        id
        name
        plan
        config {
          hasSlack
          hasFreshdesk
        }
      }
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetMeFullDocument = gql`
    query GetMeFull {
  getMe {
    id
    email
    seller {
      client {
        id
        name
        website
        additionalContext
        config {
          qualificationQuestions
        }
      }
    }
  }
}
    `;

/**
 * __useGetMeFullQuery__
 *
 * To run a query within a React component, call `useGetMeFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeFullQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeFullQuery(baseOptions?: Apollo.QueryHookOptions<GetMeFullQuery, GetMeFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeFullQuery, GetMeFullQueryVariables>(GetMeFullDocument, options);
      }
export function useGetMeFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeFullQuery, GetMeFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeFullQuery, GetMeFullQueryVariables>(GetMeFullDocument, options);
        }
export type GetMeFullQueryHookResult = ReturnType<typeof useGetMeFullQuery>;
export type GetMeFullLazyQueryHookResult = ReturnType<typeof useGetMeFullLazyQuery>;
export type GetMeFullQueryResult = Apollo.QueryResult<GetMeFullQuery, GetMeFullQueryVariables>;
export const GetOverviewDocument = gql`
    query GetOverview($timeFrame: String!, $offset: Boolean) {
  getOverview(timeFrame: $timeFrame, offset: $offset) {
    numMessages
    numUsers
    numPages
    numConversations
    keyUsers {
      id
      email
      recentMessages(timeFrame: $timeFrame) {
        id
        body
        createdAt
        sentiment
        conversation {
          id
        }
      }
    }
    keyPages {
      id
      url
      pageSentimentCount(timeFrame: $timeFrame) {
        positive
        negative
        neutral
      }
    }
    keyMessages {
      id
      aggregatedBody
      messages {
        id
        conversation {
          id
          buyer {
            email
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOverviewQuery__
 *
 * To run a query within a React component, call `useGetOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverviewQuery({
 *   variables: {
 *      timeFrame: // value for 'timeFrame'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetOverviewQuery, GetOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverviewQuery, GetOverviewQueryVariables>(GetOverviewDocument, options);
      }
export function useGetOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverviewQuery, GetOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverviewQuery, GetOverviewQueryVariables>(GetOverviewDocument, options);
        }
export type GetOverviewQueryHookResult = ReturnType<typeof useGetOverviewQuery>;
export type GetOverviewLazyQueryHookResult = ReturnType<typeof useGetOverviewLazyQuery>;
export type GetOverviewQueryResult = Apollo.QueryResult<GetOverviewQuery, GetOverviewQueryVariables>;
export const GetViewDocument = gql`
    query GetView($id: UUID!) {
  getView(id: $id) {
    id
    html
    url
  }
}
    `;

/**
 * __useGetViewQuery__
 *
 * To run a query within a React component, call `useGetViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetViewQuery(baseOptions: Apollo.QueryHookOptions<GetViewQuery, GetViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetViewQuery, GetViewQueryVariables>(GetViewDocument, options);
      }
export function useGetViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetViewQuery, GetViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetViewQuery, GetViewQueryVariables>(GetViewDocument, options);
        }
export type GetViewQueryHookResult = ReturnType<typeof useGetViewQuery>;
export type GetViewLazyQueryHookResult = ReturnType<typeof useGetViewLazyQuery>;
export type GetViewQueryResult = Apollo.QueryResult<GetViewQuery, GetViewQueryVariables>;
export const ListClientsDocument = gql`
    query ListClients {
  listClients {
    id
    name
  }
}
    `;

/**
 * __useListClientsQuery__
 *
 * To run a query within a React component, call `useListClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListClientsQuery(baseOptions?: Apollo.QueryHookOptions<ListClientsQuery, ListClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListClientsQuery, ListClientsQueryVariables>(ListClientsDocument, options);
      }
export function useListClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListClientsQuery, ListClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListClientsQuery, ListClientsQueryVariables>(ListClientsDocument, options);
        }
export type ListClientsQueryHookResult = ReturnType<typeof useListClientsQuery>;
export type ListClientsLazyQueryHookResult = ReturnType<typeof useListClientsLazyQuery>;
export type ListClientsQueryResult = Apollo.QueryResult<ListClientsQuery, ListClientsQueryVariables>;
export const ListConversationsDocument = gql`
    query ListConversations($limit: Int, $offset: Int) {
  listConversations(limit: $limit, offset: $offset) {
    id
    createdAt
    buyer {
      id
      encryptedEmail
    }
    messages {
      id
      body
      createdAt
      sentiment
      sender
      feedback {
        id
        positive
      }
      messageViews {
        view {
          id
          preview
          url
          image
        }
      }
    }
  }
  countConversations
}
    `;

/**
 * __useListConversationsQuery__
 *
 * To run a query within a React component, call `useListConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConversationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListConversationsQuery(baseOptions?: Apollo.QueryHookOptions<ListConversationsQuery, ListConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListConversationsQuery, ListConversationsQueryVariables>(ListConversationsDocument, options);
      }
export function useListConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListConversationsQuery, ListConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListConversationsQuery, ListConversationsQueryVariables>(ListConversationsDocument, options);
        }
export type ListConversationsQueryHookResult = ReturnType<typeof useListConversationsQuery>;
export type ListConversationsLazyQueryHookResult = ReturnType<typeof useListConversationsLazyQuery>;
export type ListConversationsQueryResult = Apollo.QueryResult<ListConversationsQuery, ListConversationsQueryVariables>;
export const ListSellersDocument = gql`
    query ListSellers {
  listSellers {
    id
    user {
      email
    }
  }
}
    `;

/**
 * __useListSellersQuery__
 *
 * To run a query within a React component, call `useListSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSellersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSellersQuery(baseOptions?: Apollo.QueryHookOptions<ListSellersQuery, ListSellersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSellersQuery, ListSellersQueryVariables>(ListSellersDocument, options);
      }
export function useListSellersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSellersQuery, ListSellersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSellersQuery, ListSellersQueryVariables>(ListSellersDocument, options);
        }
export type ListSellersQueryHookResult = ReturnType<typeof useListSellersQuery>;
export type ListSellersLazyQueryHookResult = ReturnType<typeof useListSellersLazyQuery>;
export type ListSellersQueryResult = Apollo.QueryResult<ListSellersQuery, ListSellersQueryVariables>;
export const ListViewsDocument = gql`
    query ListViews($url: String) {
  listViews(url: $url) {
    id
    url
    preview
    image
  }
}
    `;

/**
 * __useListViewsQuery__
 *
 * To run a query within a React component, call `useListViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListViewsQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useListViewsQuery(baseOptions?: Apollo.QueryHookOptions<ListViewsQuery, ListViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListViewsQuery, ListViewsQueryVariables>(ListViewsDocument, options);
      }
export function useListViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListViewsQuery, ListViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListViewsQuery, ListViewsQueryVariables>(ListViewsDocument, options);
        }
export type ListViewsQueryHookResult = ReturnType<typeof useListViewsQuery>;
export type ListViewsLazyQueryHookResult = ReturnType<typeof useListViewsLazyQuery>;
export type ListViewsQueryResult = Apollo.QueryResult<ListViewsQuery, ListViewsQueryVariables>;
export const RemoveConnectionDocument = gql`
    mutation RemoveConnection($connectionName: String!) {
  removeConnection(connectionName: $connectionName) {
    config {
      id
    }
    error {
      message
    }
  }
}
    `;
export type RemoveConnectionMutationFn = Apollo.MutationFunction<RemoveConnectionMutation, RemoveConnectionMutationVariables>;

/**
 * __useRemoveConnectionMutation__
 *
 * To run a mutation, you first call `useRemoveConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConnectionMutation, { data, loading, error }] = useRemoveConnectionMutation({
 *   variables: {
 *      connectionName: // value for 'connectionName'
 *   },
 * });
 */
export function useRemoveConnectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveConnectionMutation, RemoveConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveConnectionMutation, RemoveConnectionMutationVariables>(RemoveConnectionDocument, options);
      }
export type RemoveConnectionMutationHookResult = ReturnType<typeof useRemoveConnectionMutation>;
export type RemoveConnectionMutationResult = Apollo.MutationResult<RemoveConnectionMutation>;
export type RemoveConnectionMutationOptions = Apollo.BaseMutationOptions<RemoveConnectionMutation, RemoveConnectionMutationVariables>;
export const SaveClientConfigDocument = gql`
    mutation SaveClientConfig($qualificationQuestions: String) {
  saveClientConfig(qualificationQuestions: $qualificationQuestions) {
    config {
      qualificationQuestions
      id
    }
    error {
      message
    }
  }
}
    `;
export type SaveClientConfigMutationFn = Apollo.MutationFunction<SaveClientConfigMutation, SaveClientConfigMutationVariables>;

/**
 * __useSaveClientConfigMutation__
 *
 * To run a mutation, you first call `useSaveClientConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClientConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClientConfigMutation, { data, loading, error }] = useSaveClientConfigMutation({
 *   variables: {
 *      qualificationQuestions: // value for 'qualificationQuestions'
 *   },
 * });
 */
export function useSaveClientConfigMutation(baseOptions?: Apollo.MutationHookOptions<SaveClientConfigMutation, SaveClientConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveClientConfigMutation, SaveClientConfigMutationVariables>(SaveClientConfigDocument, options);
      }
export type SaveClientConfigMutationHookResult = ReturnType<typeof useSaveClientConfigMutation>;
export type SaveClientConfigMutationResult = Apollo.MutationResult<SaveClientConfigMutation>;
export type SaveClientConfigMutationOptions = Apollo.BaseMutationOptions<SaveClientConfigMutation, SaveClientConfigMutationVariables>;
export const SaveViewDocument = gql`
    mutation SaveView($id: UUID, $preview: String!, $promptText: String!) {
  saveView(id: $id, preview: $preview, promptText: $promptText) {
    view {
      id
      preview
      promptText
    }
    error {
      message
    }
  }
}
    `;
export type SaveViewMutationFn = Apollo.MutationFunction<SaveViewMutation, SaveViewMutationVariables>;

/**
 * __useSaveViewMutation__
 *
 * To run a mutation, you first call `useSaveViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveViewMutation, { data, loading, error }] = useSaveViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      preview: // value for 'preview'
 *      promptText: // value for 'promptText'
 *   },
 * });
 */
export function useSaveViewMutation(baseOptions?: Apollo.MutationHookOptions<SaveViewMutation, SaveViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveViewMutation, SaveViewMutationVariables>(SaveViewDocument, options);
      }
export type SaveViewMutationHookResult = ReturnType<typeof useSaveViewMutation>;
export type SaveViewMutationResult = Apollo.MutationResult<SaveViewMutation>;
export type SaveViewMutationOptions = Apollo.BaseMutationOptions<SaveViewMutation, SaveViewMutationVariables>;
export const UpdateClientDocument = gql`
    mutation UpdateClient($id: UUID!, $name: String, $description: String, $website: String) {
  updateClient(id: $id, name: $name, description: $description, website: $website) {
    client {
      id
      name
      description
      website
    }
    error {
      message
      field
    }
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      website: // value for 'website'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const UpdateViewListDocument = gql`
    mutation UpdateViewList($viewsList: [ViewInput]) {
  updateViewList(viewsList: $viewsList) {
    views {
      id
    }
    error {
      message
    }
  }
}
    `;
export type UpdateViewListMutationFn = Apollo.MutationFunction<UpdateViewListMutation, UpdateViewListMutationVariables>;

/**
 * __useUpdateViewListMutation__
 *
 * To run a mutation, you first call `useUpdateViewListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewListMutation, { data, loading, error }] = useUpdateViewListMutation({
 *   variables: {
 *      viewsList: // value for 'viewsList'
 *   },
 * });
 */
export function useUpdateViewListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewListMutation, UpdateViewListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewListMutation, UpdateViewListMutationVariables>(UpdateViewListDocument, options);
      }
export type UpdateViewListMutationHookResult = ReturnType<typeof useUpdateViewListMutation>;
export type UpdateViewListMutationResult = Apollo.MutationResult<UpdateViewListMutation>;
export type UpdateViewListMutationOptions = Apollo.BaseMutationOptions<UpdateViewListMutation, UpdateViewListMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $description: String, $linkedinUrl: String, $linkedinCookie: String) {
  updateUser(
    id: $id
    description: $description
    linkedinUrl: $linkedinUrl
    linkedinCookie: $linkedinCookie
  ) {
    user {
      id
      seller {
        client {
          id
        }
      }
    }
    error {
      message
      field
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      linkedinUrl: // value for 'linkedinUrl'
 *      linkedinCookie: // value for 'linkedinCookie'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;